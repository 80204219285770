let punks_attributes = {
    "3D Glasses": 287,
    "Bandana": 481,
    "Beanie": 44,
    "Big Beard": 146,
    "Big Shades": 534,
    "Black Lipstick": 617,
    "Blonde Bob": 147,
    "Blonde Short": 129,
    "Blue Eye Shadow": 266,
    "Buck Teeth": 78,
    "Cap": 350,
    "Cap Forward": 254,
    "Chinstrap": 281,
    "Choker": 48,
    "Cigarette": 959,
    "Classic Shades": 502,
    "Clown Eyes Blue": 384,
    "Clown Eyes Green": 382,
    "Clown Hair Green": 148,
    "Clown Nose": 211,
    "Cowboy Hat": 142,
    "Crazy Hair": 414,
    "Dark Hair": 157,
    "Do-rag": 300,
    "Earring": 2453,
    "Eye Mask": 293,
    "Eye Patch": 461,
    "Fedora": 186,
    "Front Beard": 273,
    "Front Beard Dark": 260,
    "Frown": 261,
    "Frumpy Hair": 442,
    "Goat": 294,
    "Gold Chain": 169,
    "Green Eye Shadow": 271,
    "Half Shaved": 147,
    "Handlebars": 263,
    "Headband": 396,
    "Hoodie": 259,
    "Horned Rim Glasses": 535,
    "Hot Lipstick": 696,
    "Knitted Cap": 419,
    "Luxurious Beard": 286,
    "Medical Mask": 175,
    "Messy Hair": 460,
    "Mohawk": 441,
    "Mohawk Dark": 429,
    "Mohawk Thin": 441,
    "Mole": 644,
    "Mustache": 288,
    "Muttonchops": 303,
    "Nerd Glasses": 571,
    "Normal Beard": 291,
    "Normal Beard Black": 289,
    "Orange Side": 68,
    "Peak Spike": 303,
    "Pigtails": 94,
    "Pilot Helmet": 54,
    "Pink With Hat": 95,
    "Pipe": 317,
    "Police Cap": 203,
    "Purple Eye Shadow": 262,
    "Purple Hair": 165,
    "Purple Lipstick": 655,
    "Red Mohawk": 147,
    "Regular Shades": 526,
    "Rosy Cheeks": 128,
    "Shadow Beard": 526,
    "Shaved Head": 300,
    "Silver Chain": 156,
    "Small Shades": 376,
    "Smile": 239,
    "Spots": 124,
    "Straight Hair": 151,
    "Straight Hair Blonde": 144,
    "Straight Hair Dark": 148,
    "Stringy Hair": 463,
    "Tassle Hat": 178,
    "Tiara": 55,
    "Top Hat": 115,
    "Two Face": 1,
    "VR": 332,
    "Vampire Hair": 147,
    "Vape": 272,
    "Welding Goggles": 86,
    "Wild Blonde": 144,
    "Wild Hair": 447,
    "Wild White Hair": 136,
    "Wizard": 1,
    "John McAfee": 1,
    "Satoshi Nakamoto": 1,
    "Pavel Durov": 1,
    "CZ": 1,
    "Gavin Wood": 1,
    "Beeple": 1,
    "Elon Musk": 1,
    "Jack Dorsey": 1,
    "Justin Sun": 1,
    "Vitalik Buterin": 1,
    "Doge": 1
};

export default punks_attributes